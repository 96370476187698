import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DataService } from '@shared/services';
import { LogsService } from '@shared/services/logs';
import { map, Observable, switchMap } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  readonly #dataService = inject(DataService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.#dataService.get$<string>('token').pipe(
      map((token) => token),
      switchMap((jwt) => {
        try {
          if (req.headers.keys().length === 0) {
            const headers = environment.API.options.headers;
            req = req.clone({
              setHeaders: {
                ...headers,
              },
            });
          }
        } catch (e) {
          LogsService.error('Error cloning request with headers:', e);
        }
        if (jwt) {
          const accept = req.headers.get('Accept') ?? null;
          if (accept === 'application/json') {
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${jwt}`,
              },
            });
          }
        }
        return next.handle(req);
      })
    );
  }
}
