import { inject, Injectable } from '@angular/core';
import { ApiServiceBase } from '@api/abstracts';
import {
  LoginKitchenerBody,
  LoginKitchenerResponse,
  LoginStoreBody,
  LoginStoreResponse,
} from '@api/endpoints/authentication/models';
import { DataService } from '@shared/services';
import { LogsService } from '@shared/services/logs';
import { Observable, take, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends ApiServiceBase<void> {
  readonly #dataService = inject(DataService);

  constructor() {
    super('authenticate');
  }

  loginStore(id: LoginStoreBody): Observable<LoginStoreResponse> {
    return this.customRequest<LoginStoreResponse>({
      method: 'POST',
      url: 'store',
      body: id,
    }).pipe(
      tap((response) => {
        LogsService.info(`🟢 Successful store connection`);
        this.#dataService.set('token', response.token).pipe(take(1)).subscribe();
      }),
      catchError((error: unknown) => {
        LogsService.error(`🔴 Store connection failed`, { report: error });
        return throwError(() => error);
      })
    );
  }

  loginKitchener(id: LoginKitchenerBody): Observable<LoginKitchenerResponse> {
    return this.customRequest<LoginKitchenerResponse>({
      method: 'POST',
      url: 'kitchener',
      body: id,
    }).pipe(
      tap(() => {
        LogsService.info(`🟢 Successful kitchener connection`);
      }),
      catchError((error: unknown) => {
        LogsService.error(`🔴 Kitchener connection failed`, { report: error });
        return throwError(() => error);
      })
    );
  }
}
