import { environmentBase } from '@environments/config';
import { Environment } from '@environments/model';
import { LogLevel } from '@shared/services/logs';

export const environment: Environment = {
  ...environmentBase,
  production: false,
  isCacheEnabled: false,
  isMockEnabled: false,
  API: {
    ...environmentBase.API,
    url: 'https://api.cooki-test.socooc.com/api/v1',
  },
  logLevel: LogLevel.INFO,
};
