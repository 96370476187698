import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@shared/services/data';
import { LogsService } from '@shared/services/logs';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  readonly #router = inject(Router);
  readonly #dataService = inject(DataService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((e) => {
        if (e instanceof HttpErrorResponse) {
          if (e.status === 0) {
            LogsService.error(`Server access problems`);
            // @TODO A REMETTRE
            // this.#dataService.clear('STORAGE').subscribe();
            // void this.#router.navigate(['/' + RoutesPath.LOGIN_STORE]);
          }
        }
        const { status, error } = e as { status: number; error: object };
        return throwError(() => ({
          server: e as object,
          error,
          status,
        }));
      })
    );
  }
}
