import { inject, Injectable, signal } from '@angular/core';
import { AuthenticationService } from '@api/endpoints/authentication';
import { LoginKitchenerBody, LoginStoreBody } from '@api/endpoints/authentication/models';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DataService } from '@shared/services';
import { KitchenerLogged, StoreLogged } from '@shared/services/auth/models';
import { forkJoin, map, Observable, take } from 'rxjs';
import { tap } from 'rxjs/operators';

const helper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly #authenticationApiService = inject(AuthenticationService);
  readonly #dataService = inject(DataService);

  #storeLogged = signal<StoreLogged | undefined>(undefined);
  storeLogged = this.#storeLogged.asReadonly();
  #kitchenerLogged = signal<KitchenerLogged | undefined>(undefined);
  kitchenerLogged = this.#kitchenerLogged.asReadonly();

  isAuthenticated(): Observable<boolean> {
    return this.#dataService.get$<string>('token').pipe(
      map((token) => {
        return token ? !helper.isTokenExpired(token) : false;
      })
    );
  }

  loadStoreLogged(): Observable<boolean> {
    return this.#dataService.get$('storeLogged').pipe(
      tap((logged: unknown) => {
        this.#storeLogged.set(logged as StoreLogged);
      }),
      map((store) => !!store)
    );
  }

  loadKitchenerLogged(): Observable<boolean> {
    return this.#dataService.get$('kitchenerLogged').pipe(
      tap((logged: unknown) => {
        this.#kitchenerLogged.set(logged as KitchenerLogged);
      }),
      map((kitchener) => !!kitchener)
    );
  }

  loginStore(id: LoginStoreBody): Observable<boolean> {
    return this.#authenticationApiService.loginStore(id).pipe(
      tap((response) => {
        const storeLogged: StoreLogged = {
          storeId: response.storeId,
          storeName: response.storeName,
        };
        this.#storeLogged.set(storeLogged);
        this.#dataService.set('storeLogged', storeLogged).pipe(take(1)).subscribe();
      }),
      map(() => true)
    );
  }

  loginKitchener(id: LoginKitchenerBody): Observable<boolean> {
    return this.#authenticationApiService.loginKitchener(id).pipe(
      tap((response) => {
        const kitchenerLogged: KitchenerLogged = {
          kitchenerId: response.kitchenerId,
          kitchenerName: response.kitchenerName,
        };
        this.#kitchenerLogged.set(kitchenerLogged);
        this.#dataService.set('kitchenerLogged', kitchenerLogged).pipe(take(1)).subscribe();
      }),
      map(() => true)
    );
  }

  logout(): Observable<boolean> {
    this.#dataService.clear('STORAGE').pipe(take(1)).subscribe();
    const remove = [this.#dataService.clear('STORAGE')];
    return forkJoin(remove).pipe(map(() => true));
  }
}
