import { Environment } from '@environments/model';
import { LogLevel } from '@shared/services/logs';
import { default as info } from 'package.json';

export const environmentBase: Environment = {
  production: true,
  applicationType: 'PROD',
  isCacheEnabled: true,
  isMockEnabled: false, // ⚠️ If you wanna activate the mocks in production, remember to delete the fileReplacements that correspond to the mock.ts in the angular.json file (not recommended).
  version: info.version,
  buildTimestamp: 1726560243743,
  logLevel: LogLevel.ERROR,
  API: {
    url: '/api',
    options: {
      headers: {
        Accept: 'application/json',
      },
    },
  },
};
