import { environment } from '@environments/environment';

export class DataCache<T> {
  data: T;
  expiredAt: number;
  expirationTime: number;
  buildTimestamp: number;

  constructor(data: T, expirationTime: number) {
    this.data = data;
    this.expirationTime = expirationTime;
    this.expiredAt = expirationTime === 0 ? 0 : Date.now() + expirationTime * 1000;
    this.buildTimestamp = environment.buildTimestamp;
  }
}
