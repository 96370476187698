import { environment } from '@environments/environment';
import { LogLevel } from '@shared/services/logs/enums';

type typeLogLevel = 'info' | 'warn' | 'error' | 'log';

export class LogsService {
  static readonly fatal = (message: unknown, ...optionalParams: unknown[]): void => {
    if (environment.logLevel >= LogLevel.FATAL) {
      LogsService.#logger('error', message, ...optionalParams);
    }
  };

  static readonly error = (message: unknown, ...optionalParams: unknown[]): void => {
    if (environment.logLevel >= LogLevel.ERROR) {
      LogsService.#logger('error', message, ...optionalParams);
    }
  };

  static readonly warn = (message: unknown, ...optionalParams: unknown[]): void => {
    if (environment.logLevel >= LogLevel.WARN) {
      LogsService.#logger('warn', message, ...optionalParams);
    }
  };

  static readonly info = (message: unknown, ...optionalParams: unknown[]): void => {
    if (environment.logLevel >= LogLevel.INFO) {
      LogsService.#logger('info', message, ...optionalParams);
    }
  };

  static readonly debug = (message: unknown, ...optionalParams: unknown[]): void => {
    if (environment.logLevel >= LogLevel.DEBUG) {
      LogsService.#logger('log', message, ...optionalParams);
    }
  };

  static readonly trace = (message: unknown, ...optionalParams: unknown[]): void => {
    if (environment.logLevel >= LogLevel.TRACE) {
      LogsService.#logger('log', message, ...optionalParams);
    }
  };

  static readonly all = (message: unknown, ...optionalParams: unknown[]): void => {
    if (environment.logLevel >= LogLevel.ALL) {
      LogsService.#logger('log', message, ...optionalParams);
    }
  };

  static readonly groupCollapsed = (level: LogLevel, message: unknown, ...optionalParams: unknown[]): void => {
    if (environment.logLevel >= level) {
      console.groupCollapsed(message, ...optionalParams);
    }
  };

  static readonly groupEnd = (level: LogLevel): void => {
    if (environment.logLevel >= level) {
      console.groupEnd();
    }
  };

  static readonly #log = (message: string): void => {
    const messageWithColor = `%c${message}`;
    const optionalParams = ['color: #f06595; font-weight: bold'];
    LogsService.#logger('info', messageWithColor, ...optionalParams);
  };

  static readonly #logger = (level: typeLogLevel, message: unknown, ...optionalParams: unknown[]): void => {
    console[level](message, ...optionalParams);
  };

  readonly updateLogLevelFromSession = (): void => {
    const logLevel = parseInt(sessionStorage.getItem('LogLevel') || '');
    if (LogLevel[logLevel]) {
      environment.logLevel = logLevel;
      LogsService.#log(`The display of logs is forced to level "${LogLevel[environment.logLevel]}" for this session.`);
    }
  };
}
