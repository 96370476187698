import { HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestMethod } from '@api/abstracts/models';
import { environment } from '@environments/environment';
import { mock } from '@mock/mock';
import { Mock } from '@mock/model';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockService {
  isActivated: boolean = environment.isMockEnabled;

  getMockResponse(method: keyof typeof RequestMethod, url: string): Observable<HttpEvent<unknown>> | undefined {
    const schema: Mock | undefined = this.#findSchema(method, url);
    if (!schema) return undefined;

    const { status = 200, schema: body } = schema;
    const isError = status >= 400 && status < 600;

    return isError
      ? throwError(
          () =>
            new HttpErrorResponse({
              error: 'Error simulated by the mock.',
              status,
              statusText: 'Error simulated by the mock.',
            })
        )
      : of(
          new HttpResponse({
            status,
            body,
          })
        );
  }

  #findSchema(method: keyof typeof RequestMethod, url: string): Mock | undefined {
    return mock.find((schema) => {
      const isMatchingMethod = method === 'GET' ? !schema.method || schema.method === method : schema.method === method;
      const isMatchingURL = schema.useStartsWith
        ? url.startsWith(`${environment.API.url}/${schema.url}`)
        : `${environment.API.url}/${schema.url}` === url;
      return isMatchingMethod && isMatchingURL;
    });
  }
}
