import { Inject, Injectable } from '@angular/core';
import { INDEXED_DB_CONFIG } from '@shared/services';
import { DataTableEnum } from '@shared/services/indexdb/enum';
import { LogsService } from '@shared/services/logs';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IndexedDbService {
  #db: IDBDatabase | null = null;

  constructor(@Inject(INDEXED_DB_CONFIG) private dbConfig: [string, number]) {}

  addOrUpdateData<T>(tableName: keyof typeof DataTableEnum, key: string, value: T): Observable<void> {
    return new Observable<void>((observer) => {
      if (!this.#db) {
        observer.error('IndexedDB not connected');
        return;
      }

      const transaction = this.#db.transaction(tableName, 'readwrite');
      const store = transaction.objectStore(tableName);
      const request = key ? store.put(value, key) : store.add(value);

      request.onsuccess = () => {
        observer.next();
        observer.complete();
      };
      request.onerror = () => observer.error(request.error);
    });
  }

  getData<T>(tableName: keyof typeof DataTableEnum, key?: string): Observable<T> {
    return new Observable<T>((observer) => {
      if (!this.#db) {
        observer.error('IndexedDB not connected');
        return;
      }

      const transaction = this.#db.transaction(tableName, 'readonly');
      const store = transaction.objectStore(tableName);
      const request = key ? store.get(key) : store.getAll();

      request.onsuccess = () => {
        observer.next(request.result as T);
        observer.complete();
      };
      request.onerror = () => observer.error(request.error);
    });
  }

  listKeys(tableName: keyof typeof DataTableEnum): Observable<string[]> {
    return new Observable<string[]>((observer) => {
      if (!this.#db) {
        observer.error('IndexedDB not connected');
        return;
      }

      const transaction = this.#db.transaction(tableName, 'readonly');
      const store = transaction.objectStore(tableName);
      const request = store.getAllKeys();

      request.onsuccess = () => {
        observer.next(request.result as string[]);
        observer.complete();
      };
      request.onerror = () => observer.error(request.error);
    });
  }

  deleteKey(tableName: keyof typeof DataTableEnum, key: string): Observable<void> {
    return new Observable<void>((observer) => {
      if (!this.#db) {
        observer.error('IndexedDB not connected');
        return;
      }

      const transaction = this.#db.transaction(tableName, 'readwrite');
      const store = transaction.objectStore(tableName);
      const request = store.delete(key);

      request.onsuccess = () => {
        observer.next();
        observer.complete();
      };
      request.onerror = () => observer.error(request.error);
    });
  }

  clearTable(tableName: keyof typeof DataTableEnum): Observable<void> {
    return new Observable<void>((observer) => {
      if (!this.#db) {
        observer.error('IndexedDB not connected');
        return;
      }

      const transaction = this.#db.transaction(tableName, 'readwrite');
      const store = transaction.objectStore(tableName);
      const request = store.clear();

      request.onsuccess = () => {
        observer.next();
        observer.complete();
      };
      request.onerror = () => observer.error(request.error);
    });
  }

  deleteDatabase(): Observable<void> {
    return new Observable<void>((observer) => {
      if (this.#db) {
        this.#db.close();
        this.#db = null;
      }

      const request = indexedDB.deleteDatabase(this.dbConfig[0]);

      request.onsuccess = () => {
        this.#db = null;
        observer.next();
        observer.complete();
      };
      request.onerror = () => observer.error(request.error);
      request.onblocked = () => observer.error('Deletion blocked');
    });
  }

  connectToDB(): Observable<void> {
    return new Observable<void>((observer) => {
      const [dbName, dbVersion] = this.dbConfig;
      const request = indexedDB.open(dbName, dbVersion);

      request.onupgradeneeded = (event) => {
        this.#db = (event.target as IDBOpenDBRequest).result;
        this.#removeObsoleteObjectStores();
        Object.keys(DataTableEnum)
          .filter((key) => isNaN(Number(key)))
          .forEach((tableName) => {
            if (!this.#db?.objectStoreNames.contains(tableName)) {
              this.#db?.createObjectStore(tableName);
            }
          });
      };

      request.onsuccess = (event) => {
        this.#db = (event.target as IDBOpenDBRequest).result;
        observer.next();
        observer.complete();
      };

      request.onerror = () => {
        LogsService.error('Database error:', request.error);
        observer.error(request.error);
      };
    });
  }

  #removeObsoleteObjectStores(): void {
    if (this.#db) {
      const existingObjectStores = Array.from(this.#db.objectStoreNames);
      const requiredObjectStores = Object.keys(DataTableEnum).filter((key) => isNaN(Number(key)));
      const obsoleteObjectStores = existingObjectStores.filter((store) => !requiredObjectStores.includes(store));

      obsoleteObjectStores.forEach((storeName) => {
        this.#db?.deleteObjectStore(storeName);
      });
    }
  }
}
