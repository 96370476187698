import { HttpClient, HttpContext } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  CustomRequest,
  HttpOptions,
  LoggerPartialBody,
  Pagination,
  QueryParamsPagination,
  RequestInterface,
} from '@api/abstracts/models';
import { CACHE, CacheInput, IS_CACHE_ENABLED } from '@api/interceptors';
import { environment } from '@environments/environment';
import { LogsService } from '@shared/services/logs';
import { LogLevel } from '@shared/services/logs/enums';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export abstract class ApiServiceBase<T> {
  readonly #http: HttpClient = inject(HttpClient);
  readonly #url = environment.API.url;

  protected constructor(protected endpoint: string) {}

  /**
   * Retourne les options concernant le cache (context)
   * @param cache Paramètres de mise en cache des données
   */
  static #getCacheOptions(cache?: CacheInput): {
    context?: HttpContext;
  } {
    const context = new HttpContext();
    if (cache) {
      context.set(IS_CACHE_ENABLED, environment.isCacheEnabled);
      context.set(CACHE, {
        cacheExpirationTime: cache.cacheExpirationTime,
        forcedKey: cache.forcedKey,
      });
    }
    return { context };
  }

  /**
   * Création de l'item
   * @param item Item à créer
   */
  create(item: Omit<T, 'id'>): Observable<T> {
    const url = `${this.#url}/${this.endpoint}`;
    return this.#http.post<T>(url, item).pipe(
      tap((response) => {
        this.#logger({ method: 'POST', url }, response, item as LoggerPartialBody);
      })
    );
  }

  /**
   * Mise à jour de l'item en put
   * @param item Item à jour
   */
  update(item: Partial<T>): Observable<T> {
    if (!(item as Partial<{ id: string }>).id) throw new Error('Missing id in item');
    const url = `${this.#url}/${this.endpoint}/${(item as Partial<{ id: string }>).id as string}`;
    return this.#http.put<T>(url, item).pipe(
      tap((response) => {
        this.#logger({ method: 'PUT', url }, response, item as LoggerPartialBody);
      })
    );
  }

  /**
   * Mise à jour d'une propriété de l'objet'
   * @param id Id de l'item à mettre à jour
   * @param item Item à jour
   */
  updateProp(id: string, item: Partial<T>): Observable<T> {
    const url = `${this.#url}/${this.endpoint}/${id}`;
    return this.#http.patch<T>(url, item).pipe(
      tap((response) => {
        this.#logger({ method: 'PATCH', url }, response, item as LoggerPartialBody);
      })
    );
  }

  /**
   * Mise à jour de la collection
   * @param collection Collection à mettre à jour
   */
  updateCollection(collection: T[]): Observable<T[]> {
    const url = `${this.#url}/${this.endpoint}`;
    return this.#http.patch<T[]>(url, collection).pipe(
      tap((response) => {
        this.#logger(
          {
            method: 'PATCH',
            url,
          },
          response,
          collection as LoggerPartialBody
        );
      })
    );
  }

  /**
   * Suppression de l'item
   * @param id Item id
   */
  delete(id: string): unknown {
    const url = `${this.#url}/${this.endpoint}/${id}`;
    return this.#http.delete(url).pipe(
      tap((response) => {
        this.#logger({ method: 'DELETE', url }, response);
      })
    );
  }

  /**
   * Retourne 1 item
   * @param id id de l'item
   * @param cache Paramètres de mise en cache des données
   */
  read(id: string, cache?: CacheInput): Observable<T> {
    const options = ApiServiceBase.#getCacheOptions(cache);
    let url = `${this.#url}/${this.endpoint}`;
    if (id && id.length > 0) {
      url = `${this.#url}/${this.endpoint}/${id}`;
    }
    return this.#http.get<T>(url, options).pipe(
      tap((response) => {
        this.#logger({ method: 'GET', url }, response);
      })
    );
  }

  /**
   * Retourne une liste d'item
   * @param queryOptions Objet contenant les paramètres de la requête
   * @param cache Paramètres de mise en cache des données
   */
  list(queryOptions: Record<string, string> = {}, cache?: CacheInput): Observable<T[]> {
    const options = ApiServiceBase.#getCacheOptions(cache);
    const queryParams = this.#objectToQuerystring(queryOptions);
    const url = `${this.#url}/${this.endpoint}${queryParams}`;
    return this.#http.get<T[]>(url, options).pipe(
      tap((response) => {
        this.#logger({ method: 'GET', url }, response);
      })
    );
  }

  /**
   * Retourne une liste d'item paginée
   * @param queryOptions Objet contenant les paramètres de la requête
   * @param cache Paramètres de mise en cache des données
   */
  listPaginate(
    queryOptions: QueryParamsPagination = {
      page: '1',
      limit: '50',
    },
    cache?: CacheInput
  ): Observable<Pagination<T>> {
    const options = ApiServiceBase.#getCacheOptions(cache);
    const queryParams = this.#objectToQuerystring(queryOptions);
    const url = `${this.#url}/${this.endpoint}${queryParams}`;
    return this.#http.get<Pagination<T>>(url, options).pipe(
      tap((response) => {
        this.#logger({ method: 'GET', url }, response);
      })
    );
  }

  customRequest<Y>(request: CustomRequest): Observable<Y> {
    const requestUrl = request.url ? `/${request.url}` : '';
    const url = request.externalUrl ? request.externalUrl : `${this.#url}/${this.endpoint}${requestUrl}`;
    let options: HttpOptions = {
      ...(request.options ? request.options : {}),
    };
    switch (request.method) {
      case 'POST':
        return this.#http.post<Y>(url, request.body ?? {}).pipe(
          tap((response) => {
            this.#logger({ method: 'POST', url }, response, request.body as LoggerPartialBody);
          })
        );
      case 'PATCH':
        return this.#http.patch<Y>(url, request.body ?? {}).pipe(
          tap((response) => {
            this.#logger({ method: 'PATCH', url }, response, request.body as LoggerPartialBody);
          })
        );
      case 'PUT':
        return this.#http.put<Y>(url, request.body ?? {}).pipe(
          tap((response) => {
            this.#logger({ method: 'PUT', url }, response, request.body as LoggerPartialBody);
          })
        );
      case 'GET':
        options = {
          ...options,
          ...ApiServiceBase.#getCacheOptions(request.cache),
        };
        const queryParams = this.#objectToQuerystring(request.query ?? {});
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return this.#http.get<Y>(`${url}${queryParams}`, options).pipe(
          tap((response) => {
            this.#logger({ method: 'GET', url }, response);
          })
        );
      case 'DELETE':
        return this.#http.delete<Y>(url).pipe(
          tap((response) => {
            this.#logger({ method: 'DELETE', url }, response);
          })
        );
      default:
        return EMPTY;
    }
  }

  /**
   * Affiche un log en console sur l'environnement local
   * @param req Paramètre de la requête
   * @param response Réponse de la requête
   * @param body Corps de la requête
   */
  #logger(req: RequestInterface, response: unknown, body: LoggerPartialBody = {}): void {
    LogsService.groupCollapsed(LogLevel.DEBUG, `${req.method.toUpperCase()} ${req.url}`);
    if (['POST', 'PUT', 'PATCH'].includes(req.method)) {
      const bodyItem = body?.body ? body.body : body;
      LogsService.debug('Body', bodyItem);
    }
    LogsService.debug('Response', response);
    LogsService.groupEnd(LogLevel.DEBUG);
  }

  /**
   * Transforme un objet en query string
   * @param obj objet
   */
  #objectToQuerystring(obj: Record<string, string | number>): string {
    if (!obj) {
      return '';
    }
    return Object.keys(obj).reduce((str, key, i) => {
      const delimiter = i === 0 ? '?' : '&';
      key = encodeURIComponent(key);
      let val;
      if (typeof obj[key] === 'object') {
        val = encodeURIComponent(JSON.stringify(obj[key]));
      } else {
        val = encodeURIComponent(obj[key]);
      }
      return val === 'undefined' ? str : [str, delimiter, key, '=', val].join('');
    }, '');
  }
}
