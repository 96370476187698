import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RequestMethod } from '@api/abstracts/models';
import { MockService } from '@api/mock';
import { LogsService } from '@shared/services/logs';
import { Observable } from 'rxjs';

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  #mockService = inject(MockService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.#mockService.isActivated) {
      const method: keyof typeof RequestMethod = req.method as keyof typeof RequestMethod;
      const response = this.#mockService.getMockResponse(method, req.urlWithParams);
      if (response) {
        LogsService.debug(`%c[MOCK] ${req.method} ${req.url}`, 'color: #868e96');
        return response;
      }

      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }
}
