{
  "name": "cooki",
  "version": "3.2.0",
  "description": "Stack de développement basée sur Angular",
  "author": {
    "name": "Gildas POTTIER",
    "email": "gildas@refacto.dev"
  },
  "scripts": {
    "prepare": "husky",
    "prestart": "node scripts/pre-start.js",
    "start": "ng serve",
    "prebuild": "node scripts/pre-build.js",
    "build:prod": "npm run prebuild && ng build --aot --output-hashing=all",
    "build:preprod": "npm run prebuild && ng build --configuration preproduction --aot --output-hashing=all",
    "build:dev": "npm run prebuild && ng build --configuration development --aot --output-hashing=all",
    "lint:check-types": "tsc --noemit",
    "lint:eslint": "eslint src/**/*.{ts,js}",
    "lint:scss": "stylelint src/**/*.scss --fix",
    "lint": "npm run lint:eslint && npm run lint:check-types && npm run lint:scss",
    "test": "ng test",
    "build:watch": "ng build --watch --configuration local",
    "build:stats": "ng build --stats-json && echo 'Open https://esbuild.github.io/analyze/'"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.0",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/forms": "^18.1.0",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@auth0/angular-jwt": "^5.2.0",
    "rxjs": "^7.8.0",
    "tslib": "^2.6.2",
    "validator": "^13.12.0",
    "zone.js": "^0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.0",
    "@angular-eslint/builder": "18.1.0",
    "@angular-eslint/eslint-plugin": "18.1.0",
    "@angular-eslint/eslint-plugin-template": "18.1.0",
    "@angular-eslint/schematics": "18.1.0",
    "@angular-eslint/template-parser": "18.1.0",
    "@angular/cli": "^18.1.0",
    "@angular/compiler-cli": "^18.1.0",
    "@commitlint/cli": "^19.3.0",
    "@commitlint/config-angular": "^19.3.0",
    "@faker-js/faker": "^8.4.1",
    "@types/events": "^3.0.3",
    "@types/jasmine": "^5.1.4",
    "@types/node": "^20.11.30",
    "@types/validator": "^13.12.1",
    "@typescript-eslint/eslint-plugin": "^7.16.0",
    "@typescript-eslint/parser": "^7.16.0",
    "autoprefixer": "^10.4.19",
    "eslint": "^8.57.0",
    "eslint-plugin-html": "^8.1.1",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-tailwindcss": "^3.17.4",
    "husky": "^9.0.11",
    "jasmine-core": "^5.1.2",
    "karma": "^6.4.3",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "postcss": "^8.4.39",
    "prettier": "^3.3.3",
    "prettier-plugin-tailwindcss": "^0.6.5",
    "stylelint": "^16.6.1",
    "stylelint-config-sass-guidelines": "^12.0.0",
    "tailwindcss": "^3.4.6",
    "typescript": "^5.5.3"
  }
}
