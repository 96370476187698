import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IndexedDbService } from '@shared/services';
import { DataCache } from '@shared/services/data/models';
import { DataTableEnum } from '@shared/services/indexdb/enum';
import { LogsService } from '@shared/services/logs';
import { EMPTY, map, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DataService {
  readonly #BUILD_TIMESTAMP = environment.buildTimestamp;
  readonly #indexDbService = inject(IndexedDbService);

  get$<T>(key: string, storeName: keyof typeof DataTableEnum = 'STORAGE'): Observable<T | null> {
    return this.#indexDbService.getData(storeName, key).pipe(
      map((cache: unknown) => {
        const dataCache = cache as DataCache<T>;
        if (dataCache && dataCache?.buildTimestamp !== this.#BUILD_TIMESTAMP && storeName === 'CACHE') {
          this.clear('CACHE').subscribe();
          return null;
        }
        if (dataCache?.expiredAt === 0 || (dataCache?.expiredAt > 0 && dataCache?.expiredAt >= Date.now())) {
          LogsService.trace('IndexDB - Get Data', {
            key,
            data: dataCache.data,
            storeName,
          });
          return dataCache.data;
        }
        if (cache !== undefined) {
          this.remove(key, storeName).subscribe();
        }
        return null;
      }),
      catchError((err) => {
        LogsService.error('IndexDB - Error retrieving data', {
          key,
          storeName,
          error: err as unknown,
        });
        return of(err as null);
      })
    );
  }

  set<T>(
    key: string,
    value: T,
    expirationTime = 0,
    storeName: keyof typeof DataTableEnum = 'STORAGE'
  ): Observable<void> {
    return this.#indexDbService.addOrUpdateData(storeName, key, new DataCache(value, expirationTime)).pipe(
      tap(() => {
        LogsService.trace(`IndexDB - Set Data`, {
          key,
          value,
          expirationTime,
          storeName,
        });
      }),
      catchError((err) => {
        LogsService.error('IndexDB - Error injecting data', {
          key,
          value,
          expirationTime,
          storeName,
          error: err as unknown,
        });
        return EMPTY;
      })
    );
  }

  remove(key: string, storeName: keyof typeof DataTableEnum = 'STORAGE'): Observable<void> {
    return this.#indexDbService.deleteKey(storeName, key).pipe(
      tap(() => {
        LogsService.trace('IndexDB - Remove Key', { key, storeName });
      }),
      catchError((err) => {
        LogsService.error('IndexDB - Error deleting a key', {
          key,
          storeName,
          error: err as unknown,
        });
        return EMPTY;
      })
    );
  }

  clear(storeName: keyof typeof DataTableEnum = 'STORAGE'): Observable<void> {
    return this.#indexDbService.clearTable(storeName).pipe(
      tap(() => {
        LogsService.trace('IndexDB - Clear Table', { storeName });
      }),
      catchError((err) => {
        LogsService.error('IndexDB - Error when deleting a table', {
          storeName,
          error: err as unknown,
        });
        return EMPTY;
      })
    );
  }

  listKeys(storeName: keyof typeof DataTableEnum = 'STORAGE'): Observable<string[]> {
    return this.#indexDbService.listKeys(storeName).pipe(
      tap((listKeys) => {
        LogsService.trace('IndexDB - List Keys', { storeName, listKeys });
      }),
      catchError((err) => {
        LogsService.error('IndexDB - Error retrieving list of keys', {
          storeName,
          error: err as unknown,
        });
        return EMPTY;
      })
    );
  }

  dropInstance(): Observable<void> {
    return this.#indexDbService.deleteDatabase().pipe(
      tap(() => {
        LogsService.trace('IndexDB - Delete Database');
      }),
      catchError((err) => {
        LogsService.error('IndexDB - Database deletion error', {
          error: err as unknown,
        });
        return EMPTY;
      })
    );
  }
}
