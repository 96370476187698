import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DataService } from '@shared/services';
import { LogsService } from '@shared/services/logs';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

export interface CacheInput {
  cacheExpirationTime: number;
  forcedKey?: string;
}

export const IS_CACHE_ENABLED = new HttpContextToken<boolean>(() => false);
export const CACHE = new HttpContextToken<CacheInput>(() => ({
  cacheExpirationTime: 0,
}));

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  readonly #dataService = inject(DataService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.method !== 'GET' || !req.context.get(IS_CACHE_ENABLED)) {
      return next.handle(req);
    }

    const { cacheExpirationTime, forcedKey } = req.context.get(CACHE);
    const key = forcedKey ?? req.urlWithParams;

    return this.#dataService.get$<unknown>(key, 'CACHE').pipe(
      mergeMap((value: unknown) => {
        if (value) {
          LogsService.debug(`%c[CACHE] ${req.method} ${req.url}`, 'color: #868e96');
          return of(new HttpResponse<unknown>({ body: value }));
        }

        return next.handle(req).pipe(
          tap((valueLoaded) => {
            if (valueLoaded instanceof HttpResponse) {
              this.#dataService.set<unknown>(key, valueLoaded.body, cacheExpirationTime, 'CACHE').subscribe();
            }
          })
        );
      })
    );
  }
}
