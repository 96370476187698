import { Injectable } from '@angular/core';
import { RefreshId } from '@shared/services/refresh/config';
import { filter, map, Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class RefreshService {
  readonly #refresh$ = new ReplaySubject<RefreshId[]>(1);

  isRefresh$(refreshId: RefreshId): Observable<boolean> {
    return this.#refresh$.pipe(
      filter((items) => items.includes(refreshId)),
      map((items) => items.length > 0)
    );
  }

  requestRefresh(refreshID: RefreshId[]): void {
    this.#refresh$.next(refreshID);
  }
}
